import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d902282 = () => interopDefault(import('../pages/a-good-company.vue' /* webpackChunkName: "pages/a-good-company" */))
const _5adc5bd8 = () => interopDefault(import('../pages/anker/index.vue' /* webpackChunkName: "pages/anker/index" */))
const _346c78a4 = () => interopDefault(import('../pages/bitte-kommen-sie-spaeter-wieder.vue' /* webpackChunkName: "pages/bitte-kommen-sie-spaeter-wieder" */))
const _abdb2472 = () => interopDefault(import('../pages/displex.vue' /* webpackChunkName: "pages/displex" */))
const _b2da42fc = () => interopDefault(import('../pages/displex-fullcover.vue' /* webpackChunkName: "pages/displex-fullcover" */))
const _68660002 = () => interopDefault(import('../pages/displex-privacy.vue' /* webpackChunkName: "pages/displex-privacy" */))
const _d15a4848 = () => interopDefault(import('../pages/displex-tablet.vue' /* webpackChunkName: "pages/displex-tablet" */))
const _7c0efe3c = () => interopDefault(import('../pages/google/index.vue' /* webpackChunkName: "pages/google/index" */))
const _bcb4a462 = () => interopDefault(import('../pages/handyrechnung-aktion.vue' /* webpackChunkName: "pages/handyrechnung-aktion" */))
const _1eae70e6 = () => interopDefault(import('../pages/handyvergleich.vue' /* webpackChunkName: "pages/handyvergleich" */))
const _ac428db0 = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _174aabac = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _445c38c6 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _661813e0 = () => interopDefault(import('../pages/merkliste.vue' /* webpackChunkName: "pages/merkliste" */))
const _73cafddc = () => interopDefault(import('../pages/panzerglass.vue' /* webpackChunkName: "pages/panzerglass" */))
const _be698530 = () => interopDefault(import('../pages/preisvorteile-sichern.vue' /* webpackChunkName: "pages/preisvorteile-sichern" */))
const _3fb95e3b = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _e22d7ce0 = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _e9d53cf2 = () => interopDefault(import('../pages/samsung/index.vue' /* webpackChunkName: "pages/samsung/index" */))
const _27402908 = () => interopDefault(import('../pages/samsung-cashback-aktion.vue' /* webpackChunkName: "pages/samsung-cashback-aktion" */))
const _cca60a8e = () => interopDefault(import('../pages/smart-home-entdecken.vue' /* webpackChunkName: "pages/smart-home-entdecken" */))
const _7f8f6485 = () => interopDefault(import('../pages/stabiles-internet.vue' /* webpackChunkName: "pages/stabiles-internet" */))
const _6da26b6a = () => interopDefault(import('../pages/themenwelten.vue' /* webpackChunkName: "pages/themenwelten" */))
const _25ac0fd8 = () => interopDefault(import('../pages/valentins-angebote.vue' /* webpackChunkName: "pages/valentins-angebote" */))
const _0de77321 = () => interopDefault(import('../pages/xiaomi.vue' /* webpackChunkName: "pages/xiaomi" */))
const _71cfbabc = () => interopDefault(import('../pages/xiaomi-smartphones.vue' /* webpackChunkName: "pages/xiaomi-smartphones" */))
const _6ca5d4e4 = () => interopDefault(import('../pages/xiaomi-zubehoer.vue' /* webpackChunkName: "pages/xiaomi-zubehoer" */))
const _f4896e9c = () => interopDefault(import('../pages/zahlung-per-handyrechnung.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung" */))
const _5f6cf353 = () => interopDefault(import('../pages/anker/product-data.ts' /* webpackChunkName: "pages/anker/product-data" */))
const _5917a7a7 = () => interopDefault(import('../pages/google/product-data.js' /* webpackChunkName: "pages/google/product-data" */))
const _69595bda = () => interopDefault(import('../pages/hilfe/lieferstatus-und-retoure.vue' /* webpackChunkName: "pages/hilfe/lieferstatus-und-retoure" */))
const _349ad387 = () => interopDefault(import('../pages/magenta-black-days/page-data.js' /* webpackChunkName: "pages/magenta-black-days/page-data" */))
const _6970b9c2 = () => interopDefault(import('../pages/samsung/product-data.ts' /* webpackChunkName: "pages/samsung/product-data" */))
const _d5ab6ab2 = () => interopDefault(import('../pages/shop/bestaetigung.vue' /* webpackChunkName: "pages/shop/bestaetigung" */))
const _15d30498 = () => interopDefault(import('../pages/shop/brodos-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/brodos-zahlungsverifizierung" */))
const _4b934e9a = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _1df99550 = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _600f4c4a = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _18b61b1b = () => interopDefault(import('../pages/shop/kundenformular/persoenliche-daten.vue' /* webpackChunkName: "pages/shop/kundenformular/persoenliche-daten" */))
const _7a0ef1fc = () => interopDefault(import('../pages/shop/kundenformular/zahlungsart.vue' /* webpackChunkName: "pages/shop/kundenformular/zahlungsart" */))
const _b97d77d6 = () => interopDefault(import('../pages/shop/telekom-bestaetigung.vue' /* webpackChunkName: "pages/shop/telekom-bestaetigung" */))
const _2ac1f484 = () => interopDefault(import('../pages/shop/telekom-bezahlung-information.vue' /* webpackChunkName: "pages/shop/telekom-bezahlung-information" */))
const _6d096b78 = () => interopDefault(import('../pages/shop/telekom-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/telekom-zahlungsverifizierung" */))
const _3af423b2 = () => interopDefault(import('../pages/shop/warenkorb.vue' /* webpackChunkName: "pages/shop/warenkorb" */))
const _c86cadb4 = () => interopDefault(import('../pages/shop/zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/zahlungsverifizierung" */))
const _ff3587a4 = () => interopDefault(import('../pages/shop/zusammenfassung.vue' /* webpackChunkName: "pages/shop/zusammenfassung" */))
const _94201d30 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _2d4c9577 = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _74ade905 = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _4a26bb6a = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _1fc81f5c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _aacfa542 = () => interopDefault(import('../pages/magenta-moments-2024/_slug.vue' /* webpackChunkName: "pages/magenta-moments-2024/_slug" */))
const _f4e4b032 = () => interopDefault(import('../special-pages/product-details.vue' /* webpackChunkName: "" */))
const _ffccdd50 = () => interopDefault(import('../special-pages/shop-list.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-good-company",
    component: _0d902282,
    name: "a-good-company"
  }, {
    path: "/anker",
    component: _5adc5bd8,
    name: "anker"
  }, {
    path: "/bitte-kommen-sie-spaeter-wieder",
    component: _346c78a4,
    name: "bitte-kommen-sie-spaeter-wieder"
  }, {
    path: "/displex",
    component: _abdb2472,
    name: "displex"
  }, {
    path: "/displex-fullcover",
    component: _b2da42fc,
    name: "displex-fullcover"
  }, {
    path: "/displex-privacy",
    component: _68660002,
    name: "displex-privacy"
  }, {
    path: "/displex-tablet",
    component: _d15a4848,
    name: "displex-tablet"
  }, {
    path: "/google",
    component: _7c0efe3c,
    name: "google"
  }, {
    path: "/handyrechnung-aktion",
    component: _bcb4a462,
    name: "handyrechnung-aktion"
  }, {
    path: "/handyvergleich",
    component: _1eae70e6,
    name: "handyvergleich"
  }, {
    path: "/infos-zum-online-kauf",
    component: _ac428db0,
    name: "infos-zum-online-kauf"
  }, {
    path: "/kontakt",
    component: _174aabac,
    name: "kontakt"
  }, {
    path: "/maintenance",
    component: _445c38c6,
    name: "maintenance"
  }, {
    path: "/merkliste",
    component: _661813e0,
    name: "merkliste"
  }, {
    path: "/panzerglass",
    component: _73cafddc,
    name: "panzerglass"
  }, {
    path: "/preisvorteile-sichern",
    component: _be698530,
    name: "preisvorteile-sichern"
  }, {
    path: "/ratenzahlung",
    component: _3fb95e3b,
    name: "ratenzahlung"
  }, {
    path: "/redirecting",
    component: _e22d7ce0,
    name: "redirecting"
  }, {
    path: "/samsung",
    component: _e9d53cf2,
    name: "samsung"
  }, {
    path: "/samsung-cashback-aktion",
    component: _27402908,
    name: "samsung-cashback-aktion"
  }, {
    path: "/smart-home-entdecken",
    component: _cca60a8e,
    name: "smart-home-entdecken"
  }, {
    path: "/stabiles-internet",
    component: _7f8f6485,
    name: "stabiles-internet"
  }, {
    path: "/themenwelten",
    component: _6da26b6a,
    name: "themenwelten"
  }, {
    path: "/valentins-angebote",
    component: _25ac0fd8,
    name: "valentins-angebote"
  }, {
    path: "/xiaomi",
    component: _0de77321,
    name: "xiaomi"
  }, {
    path: "/xiaomi-smartphones",
    component: _71cfbabc,
    name: "xiaomi-smartphones"
  }, {
    path: "/xiaomi-zubehoer",
    component: _6ca5d4e4,
    name: "xiaomi-zubehoer"
  }, {
    path: "/zahlung-per-handyrechnung",
    component: _f4896e9c,
    name: "zahlung-per-handyrechnung"
  }, {
    path: "/anker/product-data",
    component: _5f6cf353,
    name: "anker-product-data"
  }, {
    path: "/google/product-data",
    component: _5917a7a7,
    name: "google-product-data"
  }, {
    path: "/hilfe/lieferstatus-und-retoure",
    component: _69595bda,
    name: "hilfe-lieferstatus-und-retoure"
  }, {
    path: "/magenta-black-days/page-data",
    component: _349ad387,
    name: "magenta-black-days-page-data"
  }, {
    path: "/samsung/product-data",
    component: _6970b9c2,
    name: "samsung-product-data"
  }, {
    path: "/shop/bestaetigung",
    component: _d5ab6ab2,
    name: "shop-bestaetigung"
  }, {
    path: "/shop/brodos-zahlungsverifizierung",
    component: _15d30498,
    name: "shop-brodos-zahlungsverifizierung"
  }, {
    path: "/shop/fehler",
    component: _4b934e9a,
    name: "shop-fehler"
  }, {
    path: "/shop/kundenformular",
    component: _1df99550,
    children: [{
      path: "",
      component: _600f4c4a,
      name: "shop-kundenformular"
    }, {
      path: "persoenliche-daten",
      component: _18b61b1b,
      name: "shop-kundenformular-persoenliche-daten"
    }, {
      path: "zahlungsart",
      component: _7a0ef1fc,
      name: "shop-kundenformular-zahlungsart"
    }]
  }, {
    path: "/shop/telekom-bestaetigung",
    component: _b97d77d6,
    name: "shop-telekom-bestaetigung"
  }, {
    path: "/shop/telekom-bezahlung-information",
    component: _2ac1f484,
    name: "shop-telekom-bezahlung-information"
  }, {
    path: "/shop/telekom-zahlungsverifizierung",
    component: _6d096b78,
    name: "shop-telekom-zahlungsverifizierung"
  }, {
    path: "/shop/warenkorb",
    component: _3af423b2,
    name: "shop-warenkorb"
  }, {
    path: "/shop/zahlungsverifizierung",
    component: _c86cadb4,
    name: "shop-zahlungsverifizierung"
  }, {
    path: "/shop/zusammenfassung",
    component: _ff3587a4,
    name: "shop-zusammenfassung"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _94201d30,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _2d4c9577,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _74ade905,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _4a26bb6a,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/",
    component: _1fc81f5c,
    name: "index"
  }, {
    path: "/magenta-moments-2024/:slug?",
    component: _aacfa542,
    name: "magenta-moments-2024-slug"
  }, {
    path: "/geraete/:slug?",
    component: _f4e4b032,
    name: "ProductDetails"
  }, {
    path: "/:config+",
    component: _ffccdd50,
    name: "ShopList"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
